/*
  General styles to basic tag elements
*/

html {
  overflow-y: overlay;
  scrollbar-color: #005da1 transparent;
  scrollbar-width: thin;
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #262626;
}

/* Scrolls */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  background-color: #005da1;
}

::-webkit-scrollbar-button {
  display: none;
}

/* Preventing scrolling when modal is opened */
body.modal-open {
  overflow: hidden;
}

h2,
h3,
h4,
h5,
p {
  font-family: 'Open Sans';
  font-style: normal;
}

label {
  font-size: 12px;
  color: #808080;
}

/* Genereal carousel styles */

.rec.rec-item-wrapper img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.rec.rec-arrow {
  border-radius: 0;
  visibility: hidden;
}

.rec.rec-dot_active {
  box-sizing: border-box;
  padding: 0px;
  transition: all 250ms ease 0s;
  border: none;
  margin: 5px;
  background-color: #005da1 !important;
  font-size: 1.3em;
  height: 10px;
  width: 10px;
  box-shadow: rgba(0, 93, 161, 0.3) 0px 0px 0px 6px !important;
  border-radius: 50%;
  outline: none;
}

.rec.rec-dot {
  box-sizing: border-box;
  padding: 0px;
  transition: all 250ms ease 0s;
  border: none;
  margin: 5px;
  background-color: #b1b1b1;
  font-size: 1.3em;
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  box-shadow: none;
  outline: none;
}

.rec.rec-dot:hover {
  box-sizing: border-box;
  box-shadow: none;
}

.rec.rec-dot:focus {
  box-sizing: border-box;
  box-shadow: none;
}
